// @file Helper for prefetching a page before the user visits it

const prefetchedUrls = new Set<string>()

function canPrefetchUrl(url: string): boolean {
  const urlObj = new URL(url, location.href)
  // Prefetching only works for same-site resources
  return location.origin === urlObj.origin
}

// We add the prefetch link tags to the document head.
// Currently, the prefetch rule from speculationrules does not work with service worker.
// As such, we rely on either the link tag to prefetch instead.
export function prefetch(url: string): void {
  if (prefetchedUrls.has(url) || !canPrefetchUrl(url)) return
  if (document.createElement('link').relList?.supports?.('prefetch')) {
    // Prefetch with link if supported
    const link = document.createElement('link')
    link.rel = 'prefetch'
    link.setAttribute('href', url)
    document.head.append(link)
    prefetchedUrls.add(url)
  }
}
